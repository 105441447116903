import {ChevronLeftIcon, ChevronRightIcon} from '@shopify/polaris-icons';

import {useTranslations} from '@/hooks/useTranslations';

import {
  containerClass,
  paginationButtonClasses,
  paginationCurrentPageButtonClasses,
  paginationPageButtonClasses,
  disabledLink,
} from './styles';

export * from './util';

export interface PaginationProps {
  currentPage: number;
  mode: 'light' | 'dark';
  onPageChange: (pageNumber: number) => void;
  paginationRange: any[];
}

/**
 * paginationRange can be automatically generated using the usePagination hook. Example of use:
 * const paginationRange = usePagination({
 *  currentPage,
 *  totalCount: 100,
 *  siblingCount: 0,
 *  pageSize: 10,
 *  });
 *  The hook will return an array of page numbers and 'DOTS' as a string. Example:
 *  [1, 2, 3, 4, 5, 'DOTS', 10]
 */

function PaginationBookend({
  direction = 'next',
}: {
  direction: 'next' | 'previous';
}) {
  const {t} = useTranslations();
  return (
    <span className="flex items-center">
      {direction === 'previous' && (
        <ChevronLeftIcon
          width={20}
          height={20}
          className="inline"
          aria-hidden="true"
        />
      )}
      <span className="hidden sm:block ml-2">
        {direction === 'previous' && t('global:ariaLabels.prev')}
        {direction === 'next' && t('global.ariaLabels.next')}
      </span>
      {direction === 'next' && (
        <ChevronRightIcon
          width={20}
          height={20}
          className="inline"
          aria-hidden="true"
        />
      )}
    </span>
  );
}

export default function Pagination({
  mode = 'light',
  currentPage,
  onPageChange,
  paginationRange,
}: PaginationProps) {
  const {t} = useTranslations();
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];
  const componentName = 'pagination';

  return (
    <div
      data-component-name={componentName}
      className={containerClass({mode})}
      role="navigation"
    >
      {currentPage === 1 ? (
        <span
          className={disabledLink({mode})}
          data-component-name="previous-page"
          aria-label={t('global:ariaLabels.pagination.previous')}
          role="link"
          aria-disabled="true"
        >
          <PaginationBookend direction="previous" />
        </span>
      ) : (
        <a
          href={`?page=${currentPage - 1}`}
          onClick={(event) => {
            event.preventDefault();
            onPageChange(currentPage - 1);
          }}
          data-component-name="previous-page"
          className={paginationButtonClasses({mode})}
          aria-label={t('global:ariaLabels.pagination.previous')}
          aria-disabled="false"
        >
          <PaginationBookend direction="previous" />
        </a>
      )}
      <div className="flex items-center">
        {paginationRange &&
          paginationRange.map((pageNumber: any, index: number) => {
            // If the pageItem is a DOT, render the DOTS unicode character
            if (pageNumber === 'DOTS') {
              const key = `pagination-dots-${index}`;
              return (
                <p
                  key={key}
                  className={`mx-2 self-center ${
                    mode === 'dark' ? 'text-shade-40' : 'text-shade-50'
                  }`}
                  aria-hidden="true"
                >
                  &#8230;
                </p>
              );
            }

            return (
              <a
                key={pageNumber}
                onClick={(event) => {
                  event.preventDefault();
                  onPageChange(pageNumber);
                }}
                href={`?page=${pageNumber}`}
                className={`${
                  pageNumber === currentPage
                    ? paginationCurrentPageButtonClasses({mode})
                    : paginationPageButtonClasses({mode})
                }`}
                data-component-name={`page-${pageNumber}`}
                aria-label={`Page ${pageNumber}`}
              >
                <p>{pageNumber}</p>
              </a>
            );
          })}
      </div>

      {currentPage === lastPage ? (
        <span
          className={disabledLink({mode})}
          data-component-name="next-page"
          aria-label={t('global:ariaLabels.pagination.next')}
          role="link"
          aria-disabled="true"
        >
          <PaginationBookend direction="next" />
        </span>
      ) : (
        <a
          href={`?page=${currentPage + 1}`}
          onClick={(event) => {
            event.preventDefault();
            onPageChange(currentPage + 1);
          }}
          data-component-name="next-page"
          className={paginationButtonClasses({mode})}
          aria-label={t('global:ariaLabels.pagination.next')}
          aria-disabled="false"
        >
          <PaginationBookend direction="next" />
        </a>
      )}
    </div>
  );
}
